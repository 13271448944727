<template>
  <div>
    <transition :css="false" @enter="pageEnter" @leave="pageLeave" mode="out-in">
      <keep-alive include="worksList">
        <router-view :keyVisualMove.sync="keyVisualMove" />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
  import {Power1, Power3, TweenMax} from "gsap";
  import bus from "@/utils/bus";
  import {mapState} from "vuex";
  export default {
    name: "works",
    metaInfo() {
      return {
        title: "Works",
        titleTemplate: "%s | e · motion",
        meta: [
          {
            property: "og:title",
            content: "Works | e · motion",
            vmid: "og:title"
          },
          {
            property: "og:url",
            content: "https://www.emotion.co.kr/works/",
            vmid: "og:url"
          }
        ]
      };
    },
    data() {
      return {
        keyVisualMove: false
      };
    },
    /*watch: {
      keyVisualMove(val) {
        //console.log("watch", val);
      }
    },*/
    computed: {
      ...mapState(["savedPosition"])
    },
    mounted() {
      document.body.insertAdjacentHTML("beforeend", '<div class="transition-bg"></div>');
    },
    destroyed() {
      document.body.removeChild(document.querySelector(".transition-bg"));
    },
    methods: {
      pageEnter(el, done) {
        /*window.scrollTo(this.savedPosition.x, this.savedPosition.y);
      console.log(
        "pageEnter",
        this.savedPosition.y,
        document.querySelector("#app").clientHeight
      );*/
        if (this.keyVisualMove) {
          const keyVisualTxt = document.querySelector(".key-visual .contents");
          bus.$emit("winScrollTo", 0, 0, 0);
          TweenMax.fromTo(
            keyVisualTxt,
            0.5,
            {
              opacity: "0"
            },
            {
              opacity: "1",
              ease: Power3.easeOut,
              onComplete: () => {
                this.keyVisualMove = false;
                TweenMax.set(keyVisualTxt, {clearProps: "all"});
                done();
              }
            }
          );
        } else {
          const test = [
            el,
            document.querySelector(".banner-area"),
            document.querySelector("footer")
          ];
          TweenMax.fromTo(
            test,
            0.3,
            {opacity: 0},
            {
              opacity: 1,
              onComplete: () => {
                TweenMax.set(el, {clearProps: "all"});
                done();
              }
            }
          );
        }
      },
      pageLeave(el, done) {
        const keyVisualBg = document.querySelector(".key-visual .mask");
        if (this.keyVisualMove && !!keyVisualBg) {
          const keyVisualTxt = document.querySelector(".key-visual .contents");
          bus.$emit("winScrollTo", 0, 0, 700);
          TweenMax.fromTo(
            keyVisualBg,
            0.7,
            {
              height: "100vh"
            },
            {
              height: "150vh",
              ease: Power1.easeInOut,
              onComplete: () => {
                TweenMax.set(keyVisualBg, {clearProps: "all"});
                TweenMax.set(keyVisualTxt, {clearProps: "all"});
                done();
              }
            }
          );
          TweenMax.fromTo(
            keyVisualTxt,
            0.3,
            {
              opacity: "1"
            },
            {
              opacity: "0",
              ease: Power3.easeOut
            }
          );
        } else {
          const test = [
            el,
            document.querySelector(".banner-area"),
            document.querySelector("footer")
          ];
          TweenMax.fromTo(
            test,
            0.3,
            {opacity: 1},
            {
              opacity: 0,
              onComplete: () => {
                done();
              }
            }
          );
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
